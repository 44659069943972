export const POST_GO_BUTTON_FOR_MATERIAL_ISSUE_MASTER = "POST_GO_BUTTON_FOR_MATERIAL_ISSUE_MASTER"
export const POST_GO_BUTTON_FOR_MATERIAL_ISSUE_MASTER_SUCCESS = "POST_GO_BUTTON_FOR_MATERIAL_ISSUE_MASTER_SUCCESS"

export const POST_MATERIAL_ISSUE = "POST_MATERIAL_ISSUE"
export const POST_MATERIAL_ISSUE_SUCCESS = "POST_MATERIAL_ISSUE_SUCCESS"

export const GET_MATERIAL_ISSUE_LIST_PAGE = "GET_MATERIAL_ISSUE_LIST_PAGE"
export const GET_MATERIAL_ISSUE_LIST_PAGE_SUCCESS = "GET_MATERIAL_ISSUE_LIST_PAGE_SUCCESS"

export const EDIT_MATERIAL_ISSUE_LIST_PAGE = 'EDIT_MATERIAL_ISSUE_LIST_PAGE'
export const EDIT_MATERIAL_ISSUE_LIST_PAGE_SUCCESS = 'EDIT_MATERIAL_ISSUE_LIST_PAGE_SUCCESS'

export const DELETE_MATERIAL_ISSUE_LIST_PAGE = 'DELETE_MATERIAL_ISSUE_LIST_PAGE'
export const DELETE_MATERIAL_ISSUE_PAGE_SUCCESS = 'DELETE_MATERIAL_ISSUE_PAGE_SUCCESS'

export const MATERIAL_ISSUE_API_ERROR_ACTION = "MATERIAL_ISSUE_API_ERROR_ACTION"



