export const SAVE_BANK_MASTER="SAVE_BANK_MASTER"
export const SAVE_BANK_MASTER_SUCCESS="SAVE_BANK_MASTER_SUCCESS"

export const GET_BANK_LIST="GET_BANK_LIST"
export const GET_BANK_LIST_SUCCESS="GET_BANK_LIST_SUCCESS"

export const DELETE_BANK_ID="DELETE_BANK_ID"
export const DELETE_BANK_ID_SUCCESS="DELETE_BANK_ID_SUCCESS"

export const EDIT_BANK_ID="EDIT_BANK_ID"
export const EDIT_BANK_ID_SUCCESS="EDIT_BANK_ID_SUCCESS"

export const UPDATE_BANK_ID="UPDATE_BANK_ID"
export const UPDATE_BANK_ID_SUCCESS="UPDATE_BANK_ID_SUCCESS"