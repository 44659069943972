export const INVOICE_NUMBER = 'INVOICE_NUMBER'
export const INVOICE_NUMBER_SUCCESS = 'INVOICE_NUMBER_SUCCESS'

export const SAVE_SALES_RETURN_MASTER = 'SAVE_SALES_RETURN_MASTER'
export const SAVE_SALES_RETURN_MASTER_SUCCESS = 'SAVE_SALES_RETURN_MASTER_SUCCESS'

export const SALES_RETURN_LIST_API = 'SALES_RETURN_LIST_API'
export const SALES_RETURN_LIST_API_SUCCESS = 'SALES_RETURN_LIST_API_SUCCESS'

export const DELETE_SALES_RETURN_ID = 'DELETE_SALES_RETURN_ID'
export const DELETE_SALES_RETURN_ID_SUCCESS = 'DELETE_SALES_RETURN_ID_SUCCESS'

export const SALES_RETURN_ADD_BUTTON_ACTION = 'SALES_RETURN_ADD_BUTTON_ACTION'
export const SALES_RETURN_ADD_BUTTON_ACTION_SUCCESS = 'SALES_RETURN_ADD_BUTTON_ACTION_SUCCESS'

export const SALES_RETURN_CONFIRM_BUTTON_ACTION = 'SALES_RETURN_CONFIRM_BUTTON_ACTION'
export const SALES_RETURN_CONFIRM_BUTTON_ACTION_SUCCESS = 'SALES_RETURN_CONFIRM_BUTTON_ACTION_SUCCESS'

export const RETURN_UPLOAD_ACTION = 'RETURN_UPLOAD_ACTION'
export const RETURN_UPLOAD_ACTION_SUCCESS = 'RETURN_UPLOAD_ACTION_SUCCESS'




export const POST_SENT_TO_SUPERSTOCKIEST_ID = 'POST_SENT_TO_SUPERSTOCKIEST_ID'
export const POST_SENT_TO_SUPERSTOCKIEST_ID_SUCCESS = 'POST_SENT_TO_SUPERSTOCKIEST_ID_SUCCESS'

export const RETURN_APPROVE_ACTION = 'RETURN_APPROVE_ACTION'
export const RETURN_APPROVE_ACTION_SUCCESS = 'RETURN_APPROVE_ACTION_SUCCESS'


export const SALES_RETURN_API_ERROR_ACTION = 'SALES_RETURN_API_ERROR_ACTION'

