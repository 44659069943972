// Fetch Module_List  All Action type 
export const GET_PAGES_LIST_ACTION = "GET_PAGES_LIST_ACTION"
export const GET_PAGES_LIST_ACTION_SUCCESS = 'GET_PAGES_LIST_ACTION_SUCCESS'

export const SAVE_PAGE_MASTER_ACTION = "SAVE_PAGE_MASTER_ACTION"
export const SAVE_PAGE_MASTER_SUCCESS = 'SAVE_PAGE_MASTER_SUCCESS'

export const DELETE_PAGE_LIST_ID_ACTION = "DELETE_PAGE_LIST_ID_ACTION"
export const DELETE_PAGE_LIST_ID_SUCCESS = "DELETE_PAGE_LIST_ID_SUCCESS"

export const EDIT_PAGE_LIST_ID_ACTION = "EDIT_PAGE_LIST_ID_ACTION"
export const EDIT_PAGE_LIST_ID_SUCCESS = 'EDIT_PAGE_LIST_ID_SUCCESS'

export const UPDATE_PAGE_LIST_ID_ACTION = "UPDATE_PAGE_LIST_ID_ACTION"
export const UPDATE_PAGE_LIST_ID_SUCCESS = 'UPDATE_PAGE_LIST_ID_SUCCESS'

export const GET_PAGETYPE = "GET_PAGETYPE"
export const GET_PAGETYPE_SUCCESS = 'GET_PAGETYPE_SUCCESS'

export const RELATED_PAGELIST_DROPDOWN_ACTION = "RELATED_PAGELIST_DROPDOWN_ACTION"
export const RELATED_PAGELIST_DROPDOWN_SUCCESS = 'RELATED_PAGELIST_DROPDOWN_SUCCESS'

export const GET_PAGEACCESS_DROPDOWN_API = 'GET_PAGEACCESS_DROPDOWN_API'
export const GET_PAGEACCESS_DROPDOWN_API_SUCCESS = 'GET_PAGEACCESS_DROPDOWN_API_SUCCESS'

export const GET_CONTROL_TYPES = 'GET_CONTROL_TYPES'
export const GET_CONTROL_TYPES_SUCCESS = 'GET_CONTROL_TYPES_SUCCESS'

export const GET_FIELD_VALIDATIONS = 'GET_FIELD_VALIDATIONS'
export const GET_FIELD_VALIDATIONS_SUCCESS = 'GET_FIELD_VALIDATIONS_SUCCESS'

export const PAGEMASTER_API_ERROR_ACTION = 'PAGEMASTER_API_ERROR_ACTION'


export const GET_FIELD_VALIDATIONS_FOR_ALL_TYPE = 'GET_FIELD_VALIDATIONS_FOR_ALL_TYPE'
export const GET_FIELD_VALIDATIONS_FOR_ALL_TYPE_SUCCESS = 'GET_FIELD_VALIDATIONS_FOR_ALL_TYPE_SUCCESS'




