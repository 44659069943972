export const SAVE_PARTY_TYPE_API = "SAVE_PARTY_TYPE_API"
export const SAVE_PARTY_TYPE_API_SUCCESS = "SAVE_PARTY_TYPE_API_SUCCESS"

export const GET_PARTY_TYPE_LIST = "GET_PARTY_TYPE_LIST"
export const GET_PARTY_TYPE_LIST_SUCCESS = "GET_PARTY_TYPE_LIST_SUCCESS"

export const DELETE_PARTY_TYPE_ID = "DELETE_PARTY_TYPE_ID"
export const DELETE_PARTY_TYPE_ID_SUCCESS = "DELETE_PARTY_TYPE_ID_SUCCESS"

export const EDIT_PARTY_TYPE_ID = "EDIT_PARTY_TYPE_ID"
export const EDIT_PARTY_TYPE_ID_SUCCESS = "EDIT_PARTY_TYPE_ID_SUCCESS"

export const UPDATE_PARTY_TYPE_ID = "UPDATE_PARTY_TYPE_ID"
export const UPDATE_PARTY_TYPE_ID_SUCCESS = "UPDATE_PARTY_TYPE_ID_SUCCESS"

export const PARTY_TYPE_API_ERROR_ACTION = "PARTY_TYPE_API_ERROR_ACTION"


