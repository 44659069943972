export const SAVE_SUB_CLUSTER_MASTER = "SAVE_SUB_CLUSTER_MASTER"
export const SAVE_SUB_CLUSTER_MASTER_SUCCESS = "SAVE_SUB_CLUSTER_MASTER_SUCCESS"

export const GET_SUB_CLUSTER_LIST = "GET_SUB_CLUSTER_LIST"
export const GET_SUB_CLUSTER_LIST_SUCCESS = "GET_SUB_CLUSTER_LIST_SUCCESS"

export const DELETE_SUB_CLUSTER_ID = "DELETE_SUB_CLUSTER_ID"
export const DELETE_SUB_CLUSTER_ID_SUCCESS = "DELETE_SUB_CLUSTER_ID_SUCCESS"

export const EDIT_SUB_CLUSTER_ID = "EDIT_SUB_CLUSTER_ID"
export const EDIT_SUB_CLUSTER_ID_SUCCESS = "EDIT_SUB_CLUSTER_ID_SUCCESS"

export const UPDATE_SUB_CLUSTER_ID = "UPDATE_SUB_CLUSTER_ID"
export const UPDATE_SUB_CLUSTER_ID_SUCCESS = "UPDATE_SUB_CLUSTER_ID_SUCCESS"

export const SUB_CLUSTER_API_ERROR_ACTION = "SUB_CLUSTER_API_ERROR_ACTION"