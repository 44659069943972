/// for drop down list
export const GET_DESIGNATIONID = "GET_DESIGNATIONID"
export const GET_DESIGNATIONID_SUCCESS = "GET_DESIGNATIONID_SUCCESS"

export const GET_STATE = "GET_STATE"
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS"

export const GET_CITY_ON_DISTRICT = "GET_CITY_ON_DISTRICT"
export const GET_CITY_ON_DISTRICT_SUCCESS = "GET_CITY_ON_DISTRICT_SUCCESS"

export const GET_REGION = "GET_REGION"
export const GET_REGION_SUCCESS = "GET_REGION_SUCCESS"

export const GET_COMPANY = "GET_COMPANY"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"

/// get ,post,edit ,delete methods 
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST"
export const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS"

export const SAVE_EMPLOYEE_MASTER = "SAVE_EMPLOYEE_MASTER"
export const SAVE_EMPLOYEE_MASTER_SUCCESS = "SAVE_EMPLOYEE_MASTER_SUCCESS"

export const DELETE_EMPLOYEE_ID = "DELETE_EMPLOYEE_ID"
export const DELETE_EMPLOYEE_ID_SUCCESS = "DELETE_EMPLOYEE_ID_SUCCESS"

export const EDIT_EMPLOYEE_ID = "EDIT_EMPLOYEE_ID"
export const EDIT_EMPLOYEE_ID_SUCCESS = "EDIT_EMPLOYEE_ID_SUCCESS"

export const UPDATE_EMPLOYEE_ID = "UPDATE_EMPLOYEE_ID"
export const UPDATE_EMPLOYEE_ID_SUCCESS = "UPDATE_EMPLOYEE_ID_SUCCESS"

export const GET_COMPANYNAME_BY_EMPLOYEETYPES_ID = "GET_COMPANYNAME_BY_EMPLOYEETYPES_ID"
export const GET_COMPANYNAME_BY_EMPLOYEETYPES_ID_SUCCESS = "GET_COMPANYNAME_BY_EMPLOYEETYPES_ID_SUCCESS"

export const EMPLOYEE_API_ERROR_ACTION = "EMPLOYEE_API_ERROR_ACTION"




