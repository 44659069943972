export const GST_R1_REPORT_API = 'GST_R1_REPORT_API'

export const GST_R1_REPORT_API_SUCCESS = 'GST_R1_REPORT_API_SUCCESS'


export const GST_R3B_REPORT_API = 'GST_3B_REPORT_API'

export const GST_R3B_REPORT_API_SUCCESS = 'GST_3B_REPORT_API_SUCCESS'




export const GST_R1_REPORT_API_ERROR_ACTION = 'GST_R1_REPORT_API_ERROR_ACTION'





