export const SAVE_BANK_ASSIGN = "SAVE_BANK_ASSIGN"
export const SAVE_BANK_ASSIGN_SUCCESS = "SAVE_BANK_ASSIGN_SUCCESS"

export const PARTY_BANK_FILTER = "PARTY_BANK_FILTER"
export const PARTY_BANK_FILTER_SUCCESS = "PARTY_BANK_FILTER_SUCCESS"

export const EDIT_BANK_ASSIGN_ID = "EDIT_BANK_ASSIGN_ID"
export const EDIT_BANK_ASSIGN_ID_SUCCESS = "EDIT_BANK_ASSIGN_ID_SUCCESS"

export const UPDATE_BANK_ASSIGN_ID = "UPDATE_BANK_ASSIGN_ID"
export const UPDATE_BANK_ASSIGN_ID_SUCCESS = "UPDATE_BANK_ASSIGN_ID_SUCCESS"

export const BANK_ASSIGN_API_ERROR_ACTION = "BANK_ASSIGN_API_ERROR_ACTION"



