export const POST_INWARD = 'POST_INWARD'
export const POST_INWARD_SUCCESS = 'POST_INWARD_SUCCESS'

export const GET_INWARD_LIST_PAGE = 'GET_INWARD_LIST_PAGE'
export const GET_INWARD_LIST_PAGE_SUCCESS = 'GET_INWARD_LIST_PAGE_SUCCESS'

export const DELETE_INWARD_LIST_PAGE = 'DELETE_INWARD_LIST_PAGE'
export const DELETE_INWARD_LIST_PAGE_SUCCESS = 'DELETE_INWARD_LIST_PAGE_SUCCESS'

export const INWARD_LIST_FILTERS = 'INWARD_LIST_FILTERS'

export const MAKE_INWARD = 'MAKE_INWARD'
export const MAKE_INWARD_SUCCESS = 'MAKE_INWARD_SUCCESS'