export const POST_METHOD_FOR_GENERAL_API="POST_METHOD_FOR_GENERAL_API"
export const POST_METHOD_FOR_GENERAL_API_SUCCESS="POST_METHOD_FOR_GENERAL_API_SUCCESS"

export const POST_GENERAL_LIST="POST_GENERAL_LIST"
export const POST_GENERAL_LIST_SUCCESS="POST_GENERAL_LIST_SUCCESS"

export const DELETE_GENERAL_ID="DELETE_GENERAL_ID"
export const DELETE_GENERAL_ID_SUCCESS="DELETE_GENERAL_ID_SUCCESS"

export const EDIT_GENERAL_ID="EDIT_GENERAL_ID"
export const EDIT_GENERAL_ID_SUCCESS="EDIT_GENERAL_ID_SUCCESS"

export const UPDATE_GENERAL_ID="UPDATE_GENERAL_ID"
export const UPDATE_GENERAL_ID_SUCCESS="UPDATE_GENERAL_ID_SUCCESS"

export const GENARAL_MASTER_BY_TYPE="GENARAL_MASTER_BY_TYPE"
export const GENARAL_MASTER_BY_TYPE_SUCCESS="GENARAL_MASTER_BY_TYPE_SUCCESS"

export const GENERAL_MASTER_SUB_TYPE="GENERAL_MASTER_SUB_TYPE"
export const GENERAL_MASTER_SUB_TYPE_SUCCESS="GENERAL_MASTER_SUB_TYPE_SUCCESS"