export const SAVE_SUBGROUPLIST = "SAVE_SUBGROUPLIST"
export const SAVE_SUBGROUPLIST_SUCCESS = "SAVE_SUBGROUPLIST_SUCCESS"

export const GET_SUBGROUP_LIST = "GET_SUBGROUP_LIST"
export const GET_SUBGROUP_LIST_SUCCESS = "GET_SUBGROUP_LIST_SUCCESS"

export const DELETE_SUBGROUP_LIST_ID = "DELETE_SUBGROUPMASTER_ID"
export const DELETE_SUBGROUP_LIST_ID_SUCCESS = "DELETE_SUBGROUPMASTER_ID_SUCCESS"

export const EDIT_SUBGROUPMASTER_ID = "EDIT_SUBGROUPMASTER_ID"
export const EDIT_SUBGROUPMASTER_ID_SUCCESS = "EDIT_SUBGROUPMASTER_ID_SUCCESS"

export const UPDATE_SUBGROUPMASTER_ID = "UPDATE_SUBGROUPMASTER_ID"
export const UPDATE_SUBGROUPMASTER_ID_SUCCESS = "UPDATE_SUBGROUPMASTER_ID_SUCCESS"

export const POST_SUBGROUPLIST = "POST_SUBGROUPLIST"
export const POST_SUBGROUPLIST_SUCCESS = "POST_SUBGROUPLIST_SUCCESS"

export const SUBGROUP_API_ERROR_ACTION = "SUBGROUP_API_ERROR_ACTION"



