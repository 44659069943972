// post api
export const SAVE_MRP_MASTER = "SAVE_MRP_MASTER"
export const SAVE_MRP_MASTER_SUCCESS = "SAVE_MRP_MASTER_SUCCESS"

// Go Button post api
export const GO_BUTTON_FOR_MRP_MASTER = "GO_BUTTON_FOR_MRP_MASTER"
export const GO_BUTTON_FOR_MRP_MASTER_SUCCESS = "GO_BUTTON_FOR_MRP_MASTER_SUCCESS"

//listpage
export const GET_MRP_LIST = "GET_MRP_LIST"
export const GET_MRP_LIST_SUCCESS = "GET_MRP_LIST_SUCCESS"

// delete api For MRP List Page
export const DELETE_MRP_LIST = "DELETE_MRP_LIST"
export const DELETE_MRP_LIST_SUCCESS = "DELETE_MRP_LIST_SUCCESS"

// delete api For MRP Master Page
export const DELETE_MRP_MASTER_ID = "DELETE_MRP_MASTER_ID"
export const DELETE_MRP_MASTER_ID_SUCCESS = "DELETE_MRP_MASTER_ID_SUCCESS"

export const POST_VIEW_MRP = "POST_VIEW_MRP"
export const POST_VIEW_MRP_SUCCESS = "POST_VIEW_MRP_SUCCESS"

export const MRP_API_ERROR_ACTION = "MRP_API_ERROR_ACTION"


