export const POST_EMPLOYEETYPE_SUBMIT = "POST_EMPLOYEETYPE_SUBMIT"
export const POST_EMPLOYEETYPE_SUBMIT_SUCCESS = "POST_EMPLOYEETYPE_SUBMIT_SUCCESS"

export const GET_EMPLOYEE_TYPE_LIST = "GET_EMPLOYEE_TYPE_LIST"
export const GET_EMPLOYEE_TYPE_LIST_SUCCESS = "GET_EMPLOYEE_TYPE_LIST_SUCCESS"

export const DELETE_EMPLOYEE_TYPE_ID = "DELETE_EMPLOYEE_TYPE_ID"
export const DELETE_EMPLOYEE_TYPE_ID_SUCCESS = "DELETE_EMPLOYEE_TYPE_ID_SUCCESS"

export const EDIT_EMPLOYEE_TYPE_ID = "EDIT_EMPLOYEE_TYPE_ID"
export const EDIT_EMPLOYEE_TYPE_ID_SUCCESS = "EDIT_EMPLOYEE_TYPE_ID_SUCCESS"

export const UPDATE_EMPLOYEE_TYPE_ID = "UPDATE_EMPLOYEE_TYPE_ID"
export const UPDATE_EMPLOYEE_TYPE_ID_SUCCESS = "UPDATE_EMPLOYEE_TYPE_ID_SUCCESS"

export const EMPLOYEE_TYPE_API_ERROR_ACTION = "EMPLOYEE_TYPE_API_ERROR_ACTION"


