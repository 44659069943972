export const SAVE_SYSTEM_SETTING = "SAVE_SYSTEM_SETTING"
export const SAVE_SYSTEM_SETTING_SUCCESS = "SAVE_SYSTEM_SETTING_SUCCESS"


export const DELETE_SYSTEM_SETTING_LIST_ID = "DELETE_SYSTEM_SETTING_LIST_ID"
export const DELETE_SYSTEM_SETTING_LIST_ID_SUCCESS = "DELETE_SYSTEM_SETTING_LIST_ID_SUCCESS"

export const EDIT_SYSTEM_SETTING_ID = "EDIT_SYSTEM_SETTING_ID"
export const EDIT_SYSTEM_SETTING_ID_SUCCESS = "EDIT_SYSTEM_SETTING_ID_SUCCESS"

export const UPDATE_SYSTEM_SETTING_ID = "UPDATE_SYSTEM_SETTING_ID"
export const UPDATE_SYSTEM_SETTING_ID_SUCCESS = "UPDATE_SYSTEM_SETTING_ID_SUCCESS"


export const SYSTEM_SETTING_API_ERROR_ACTION = "SYSTEM_SETTING_API_ERROR_ACTION"


