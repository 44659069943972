
export const GO_BUTTON_IMPORT_EXCEL_PARTY_MAP = "GO_BUTTON_IMPORT_EXCEL_PARTY_MAP"
export const GO_BUTTON_IMPORT_EXCEL_PARTY_MAP_SUCCESS = "GO_BUTTON_IMPORT_EXCEL_PARTY_MAP_SUCCESS"

export const SAVE_IMPORT_EXCEL_PARTY_MAP = "SAVE_IMPORT_EXCEL_PARTY_MAP"
export const SAVE_IMPORT_EXCEL_PARTY_MAP_SUCCESS = "SAVE_IMPORT_EXCEL_PARTY_MAP_SUCCESS"

export const GET_IMPORT_MASTER_MAP_LIST = "GET_IMPORT_MASTER_MAP_LIST"
export const GET_IMPORT_MASTER_MAP_LIST_SUCCESS = "GET_IMPORT_MASTER_MAP_LIST_SUCCESS"

export const INVOICE_EXCEL_UPLOAD_SAVE = "INVOICE_EXCEL_UPLOAD_SAVE"
export const INVOICE_EXCEL_UPLOAD_SAVE_SUCCESS = "INVOICE_EXCEL_UPLOAD_SAVE_SUCCESS"

export const RETAILER_EXCEL_UPLOAD_SAVE = "RETAILER_EXCEL_UPLOAD_SAVE"
export const RETAILER_EXCEL_UPLOAD_SAVE_SUCCESS = "RETAILER_EXCEL_UPLOAD_SAVE_SUCCESS"

export const RETAILER_EXCEL_UPLOAD_API_ERROR_ACTION = "RETAILER_EXCEL_UPLOAD_API_ERROR_ACTION"




