// const SERVER_HOST_PATH = "https://cbmfooderp.com/api";

// export const ERP_LINK = "https://cbmfooderp.com";

// const SERVER_HOST_PATH = "http://10.4.5.68:8000";

const SERVER_HOST_PATH = "http://43.225.53.91:8000";

export const ERP_LINK = "http://43.225.53.91:3000";

// http://43.225.53.91/

export default SERVER_HOST_PATH;
