// post api
export const POST_PRICE_LIST_DATA = "POST_PRICE_LIST_DATA"
export const POST_PRICE_LIST_DATA_SUCCESS = "POST_PRICE_LIST_DATA_SUCCESS"

// list by party
export const PRICE_LIST_BY_PARTY_ACTION = "PRICE_LIST_BY_PARTY_ACTION"
export const PRICE_LIST_BY_PARTY_ACTION_SUCCESS = "PRICE_LIST_BY_PARTY_ACTION_SUCCESS"

//list by company
export const PRICE_LIST_BY_COMPANY = "UPDATE_PPRICE_LIST_BY_COMPANYRICE_LIST"
export const PRICE_LIST_BY_COMPANY_SUCCESS = "PRICE_LIST_BY_COMPANY_SUCCESS"

// delete api
export const DELETE_PRICE_LIST = "DELETE_PRICE_LIST"
export const DELETE_PRICE_LIST_SUCCESS = "DELETE_PRICE_LIST_SUCCESS"


export const EDIT_PRICE_LIST = "EDIT_PRICE_LIST"
export const EDIT_PRICE_LIST_SUCCESS = "EDIT_PRICE_LIST_SUCCESS"

export const UPDATE_PRICE_LIST = "UPDATE_PRICE_LIST"
export const UPDATE_PRICE_LIST_SUCCESS = "UPDATE_PRICE_LIST_SUCCESS"


export const PRICE_lIST_API_ERROR_ACTION = "PRICE_lIST_API_ERROR_ACTION"
