export const SAVE_SALES_MAN_MASTER = "SAVE_SALES_MAN_MASTER"
export const SAVE_SALES_MAN_MASTER_SUCCESS = "SAVE_SALES_MAN_MASTER_SUCCESS"

export const GET_SALESMAN_LIST = "GET_SALESMAN_LIST"
export const GET_SALESMAN_LIST_SUCCESS = "GET_SALESMAN_LIST_SUCCESS"

export const DELETE_SALESMAN_ID = "DELETE_SALESMAN_ID"
export const DELETE_SALESMAN_ID_SUCCESS = "DELETE_SALESMAN_ID_SUCCESS"

export const EDIT_SALESMAN_ID = "EDIT_SALESMAN_ID"
export const EDIT_SALESMAN_ID_SUCCESS = "EDIT_SALESMAN_ID_SUCCESS"

export const UPDATE_SALESMAN_ID = "UPDATE_SALESMAN_ID"
export const UPDATE_SALESMAN_ID_SUCCESS = "UPDATE_SALESMAN_ID_SUCCESS"

export const SALESMAN_API_ERROR_ACTION = "SALESMAN_API_ERROR_ACTION"


