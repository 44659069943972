export const GET_GROUP_TYPES_LIST = "GET_GROUP_TYPES_LIST"
export const GET_GROUP_TYPES_LIST_SUCCESS = "GET_GROUP_TYPES_LIST_SUCCESS"

export const SAVE_GROUP_TYPE_MASTER = "SAVE_GROUP_TYPE_MASTER"
export const SAVE_GROUP_TYPE_MASTER_SUCCESS = "SAVE_GROUP_TYPE_MASTER_SUCCESS"

export const EDIT_GROUP_TYPE_ID = "EDIT_GROUP_TYPE_ID"
export const EDIT_GROUP_TYPE_ID_SUCCESS = "EDIT_GROUP_TYPE_ID_SUCCESS"

export const UPDATE_GROUP_TYPE_ID = "UPDATE_GROUP_TYPE_ID"
export const UPDATE_GROUP_TYPE_ID_SUCCESS = "UPDATE_GROUP_TYPE_ID_SUCCESS"

export const DELETE_GROUP_TYPE_ID = "DELETE_GROUP_TYPE_ID"
export const DELETE_GROUP_TYPE_ID_SUCCESS = "DELETE_GROUP_TYPE_ID_SUCCESS"

export const GROUP_TYPE_API_ERROR_ACTION = "GROUP_TYPE_API_ERROR_ACTION"




