export const SAVE_IMPORT_FIELD_ADD = "SAVE_IMPORT_FIELD_ADD"
export const SAVE_IMPORT_FIELD_ADD_SUCCESS= "SAVE_IMPORT_FIELD_ADD_SUCCESS"

export const POST_IMPORT_FIELD_ADD="POST_IMPORT_FIELD_ADD"
export const POST_IMPORT_FIELD_ADD_SUCCESS="POST_IMPORT_FIELD_ADD_SUCCESS"

export const DELETE_IMPORT_FIELD_ADD="DELETE_IMPORT_FIELD_ADD"
export const DELETE_IMPORT_FIELD_ADD_SUCCESS="DELETE_IMPORT_FIELD_ADD_SUCCESS"

export const EDIT_IMPORT_FIELD_ADD="EDIT_IMPORT_FIELD_ADD"
export const EDIT_IMPORT_FIELD_ADD_SUCCESS="EDIT_IMPORT_FIELD_ADD_SUCCESS"

export const UPDATE_IMPORT_FIELD_ADD="UPDATE_IMPORT_FIELD_ADD"
export const UPDATE_IMPORT_FIELD_ADD_SUCCESS="UPDATE_IMPORT_FIELD_ADD_SUCCESS"

export const IMPORT_EXCEL_TYPE="IMPORT_EXCEL_TYPE"
export const IMPORT_EXCEL_TYPE_SUCCESS="IMPORT_EXCEL_TYPE_SUCCESS"

export const IMPORT_FIELD_ADD_API_ERROR_ACTION = "IMPORT_FIELD_ADD_API_ERROR_ACTION"
