export const GET_EMPLOYEE_FOR_USER_REGISTRATION = "GET_EMPLOYEE_FOR_USER_REGISTRATION"
export const GET_EMPLOYEE_FOR_USER_REGISTRATION_SUCCESS = "GET_EMPLOYEE_FOR_USER_REGISTRATION_SUCCESS"

export const ADD_USER = "ADD_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"

export const GET_USER_LIST_FOR_USER = "GET_USER_LIST_FOR_USER"
export const GET_USER_LIST_FOR_USER_SUCCESS = "GET_USER_LIST_FOR_USER_SUCCESS"

export const DELETE_USER_ACTION = "DELETE_USER_ACTION"
export const DELETE_USER_ACTION_SUCCESS = "DELETE_USER_ACTION_SUCCESS"

export const EDIT_USER_ACTION = "EDIT_USER_ACTION"
export const EDIT_USER_ACTION_SUCCESS = "EDIT_USER_ACTION_SUCCESS"

export const UPDATE_USER_ACTION = "UPDATE_USER_ACTION"
export const UPDATE_USER_ACTION_SUCCESS = "UPDATE_USER_ACTION_SUCCESS"

export const GET_USER_PARTIES_FOR_USER_MASTER = "GET_USER_PARTIES_FOR_USER_MASTER"
export const GET_USER_PARTIES_FOR_USER_MASTER_SUCCESS = "GET_USER_PARTIES_FOR_USER_MASTER_SUCCESS"

export const USER_API_ERROR_ACTION = "USER_API_ERROR_ACTION"





