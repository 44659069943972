export const GET_PARTY_SETTING_API = 'GET_PARTY_SETTING_API'
export const GET_PARTY_SETTING_API_SUCCESS = 'GET_PARTY_SETTING_API_SUCCESS'

export const SAVE_PARTY_SETTING_MASTER = 'SAVE_PARTY_SETTING_MASTER'
export const SAVE_PARTY_SETTING_MASTER_SUCCESS = 'SAVE_PARTY_SETTING_MASTER_SUCCESS'

export const GET_PARTY_SETTING_API_ERROR_ACTION = 'GET_PARTY_SETTING_API_ERROR_ACTION'






