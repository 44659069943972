export const POST_CLAIM_CREATE_SUMMARY_API = 'POST_CLAIM_CREATE_SUMMARY_API'
export const POST_CLAIM_CREATE_SUMMARY_API_SUCCESS = 'POST_CLAIM_CREATE_SUMMARY_API_SUCCESS'
export const DELETE_CLAIM_ID = 'DELETE_CLAIM_ID'
export const DELETE_CLAIM_ID_SUCCESS = 'DELETE_CLAIM_ID_SUCCESS'

export const CLAIM_LIST_API = 'CLAIM_LIST_API'
export const CLAIM_LIST_API_SUCCESS = 'CLAIM_LIST_API_SUCCESS'

export const POST_CLAIM_CREATE_SUMMARY_API_ERROR_ACTION = 'POST_CLAIM_CREATE_SUMMARY_API_ERROR_ACTION'


