export const SAVE_POS_ROLEACCESS = "SAVE_POS_ROLEACCESS"
export const SAVE_POS_ROLEACCESS_SUCCESS = "SAVE_POS_ROLEACCESS_SUCCESS"

export const GET_POS_ROLEACCESS = "GET_POS_ROLEACCESS"
export const GET_POS_ROLEACCESS_SUCCESS = "GET_POS_ROLEACCESS_SUCCESS"


export const POS_ROLEACCESS_ERROR = "POS_ROLEACCESS_ERROR"
export const POS_ROLEACCESS_ERROR_SUCCESS = "POS_ROLEACCESS_ERROR_SUCCESS"






