export const POST_PARTY_MASTER_BULK_UPDATE_PAGE = 'POST_PARTY_MASTER_BULK_UPDATE_PAGE'
export const POST_PARTY_MASTER_BULK_UPDATE_PAGE_SUCCESS = 'POST_PARTY_MASTER_BULK_UPDATE_PAGE_SUCCESS'

export const GO_BUTTON_FOR_PARTY_MASTER_BULK_UPDATE_PAGE = 'GO_BUTTON_FOR_PARTY_MASTER_BULK_UPDATE_PAGE'
export const GO_BUTTON_FOR_PARTY_MASTER_BULK_UPDATE_PAGE_SUCCESS = 'GO_BUTTON_FOR_PARTY_MASTER_BULK_UPDATE_PAGE_SUCCESS'

export const POST_PARTY_NAME_DROPDOWN = 'POST_PARTY_NAME_DROPDOWN'
export const POST_PARTY_NAME_SUCCESS = 'POST_PARTY_NAME_SUCCESS'

export const POST_SELECT_FIELD_DROPDOWN = 'POST_SELECT_FIELD_DROPDOWN'
export const POST_SELECT_FIELD_SUCCESS = 'POST_SELECT_FIELD_SUCCESS'

export const UPDATE_PARTY_MASTER_BULK = 'UPDATE_PARTY_MASTER_BULK'
export const UPDATE_PARTY_MASTER_BULK_SUCCESS = 'UPDATE_PARTY_MASTER_BULK_SUCCESS'

export const PARTYBULK_API_ERROR_ACTION = 'PARTYBULK_API_ERROR_ACTION'




