export const SAVE_GST_MASTER = "SAVE_GST_MASTER"
export const SAVE_GST_MASTER_SUCCESS = "SAVE_GST_MASTER_SUCCESS"

export const GET_GST_LIST = "GET_GST_LIST"
export const GET_GST_LIST_SUCCESS = "GET_GST_LIST_SUCCESS"

export const DELETE_GST_LIST_ID = "DELETE_GST_LIST_ID"
export const DELETE_GST_LIST_ID_SUCCESS = "DELETE_GST_LIST_ID_SUCCESS"

export const GO_BUTTON_FOR_GST_MASTER = "GO_BUTTON_FOR_GST_MASTER"
export const GO_BUTTON_FOR_GST_MASTER_SUCCESS = "GO_BUTTON_FOR_GST_MASTER_SUCCESS"

export const DELETE_GST_ID_FOR_MASTER = "DELETE_GST_ID_FOR_MASTER"
export const DELETE_GST_ID_FOR_MASTER_SUCCESS = "DELETE_GST_ID_FOR_MASTER_SUCCESS"

export const POST_VIEW_GST = "POST_VIEW_GST"
export const POST_VIEW_GST_SUCCESS = "POST_VIEW_GST_SUCCESS"

export const GST_API_ERROR_ACTION = "GST_API_ERROR_ACTION"


