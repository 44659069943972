

export const GET_PRODUCTION_RE_ISSUE_LIST_PAGE = 'GET_PRODUCTION_RE_ISSUE_LIST_PAGE'
export const GET_PRODUCTION_RE_ISSUE_LIST_PAGE_SUCCESS = 'GET_PRODUCTION_RE_ISSUE_LIST_PAGE_SUCCESS'

export const SAVE_PRODUCTION_RE_ISSUE_ADD_PAGE = 'SAVE_PRODUCTION_RE_ISSUE_ADD_PAGE'
export const SAVE_PRODUCTION_RE_ISSUE_ADD_PAGE_SUCCESS = 'SAVE_PRODUCTION_RE_ISSUE_ADD_PAGE_SUCCESS'

export const EDIT_PRODUCTION_RE_ISSUE = 'EDIT_PRODUCTION_RE_ISSUE'
export const EDIT_PRODUCTION_RE_ISSUE_SUCCESS = 'EDIT_PRODUCTION_RE_ISSUE_SUCCESS'

export const UPDATE_PRODUCTION_RE_ISSUE = 'UPDATE_PRODUCTION_RE_ISSUE'
export const UPDATE_PRODUCTION_RE_ISSUE_SUCCESS = 'UPDATE_PRODUCTION_RE_ISSUE_SUCCESS'

export const DELETE_PRODUCTION_RE_ISSUE_ID = 'DELETE_PRODUCTION_RE_ISSUE_ID'
export const DELETE_PRODUCTION_RE_ISSUE_SUCCESS = 'DELETE_PRODUCTION_RE_ISSUE_SUCCESS'

export const GO_BUTTON_FOR_PRODUCTION_RE_ISSUE_ADD_PAGE = 'GO_BUTTON_FOR_PRODUCTION_RE_ISSUE_ADD_PAGE'
export const GO_BUTTON_FOR_PRODUCTION_RE_ISSUE_ADD_PAGE_SUCCESS = 'GO_BUTTON_FOR_PRODUCTION_RE_ISSUE_ADD_PAGE_SUCCESS'

export const ITEM_FOR_PRODUNCTION_RE_ISSUE = 'ITEM_FOR_PRODUNCTION_RE_ISSUE'
export const ITEM_FOR_PRODUNCTION_RE_ISSUE_SUCCESS = 'ITEM_FOR_PRODUNCTION_RE_ISSUE_SUCCESS'

export const MAKE_BTN_FOR_PRODUNCTION_RE_ISSUE_STP_ACTION = 'MAKE_BTN_FOR_PRODUNCTION_RE_ISSUE_STP_ACTION'
export const MAKE_BTN_FOR_PRODUNCTION_RE_ISSUE_STP_ACTION_SUCCESS = 'MAKE_BTN_FOR_PRODUNCTION_RE_ISSUE_STP_ACTION_SUCCESS'






