// get group items
export const GET_ITEM_GROUP_FOR_DROPDOWN = "GET_ITEM_GROUP_FOR_DROPDOWN"
export const GET_ITEM_GROUP_FOR_DROPDOWN_SUCCESS = "GET_ITEM_GROUP_FOR_DROPDOWN_SUCCESS"

// get Item Tag api
export const GET_ITEMTAG_API = "GET_ITEMTAG_API"
export const GET_ITEMTAG_API_SUCCESS = "GET_ITEMTAG_API_SUCCESS"
// get api
export const GET_BRANDTAG_API = "GET_BRANDTAG_API"
export const GET_BRANDTAG_API_SUCCESS = "GET_BRANDTAG_API_SUCCESS"

export const GET_ITEM_LIST_API = "GET_ITEM_LIST_API"
export const GET_ITEM_LIST_API_SUCCESS = "GET_ITEM_LIST_API_SUCCESS"

// post api
export const SAVE_ITEM_MASTER = "SAVE_ITEM_MASTER"
export const SAVE_ITEM_MASTER_SUCCESS = "SAVE_ITEM_MASTER_SUCCESS"

// delete api
export const DELETE_ITEM_ID = "DELETE_ITEM_ID"
export const DELETE_ITEM_ID_SUCCESS = "DELETE_ITEM_ID_SUCCESS"

// edit api
export const EDIT_ITEM_ID = "EDIT_ITEM_ID"
export const EDIT_ITEM_ID_SUCCESS = "EDIT_ITEM_ID_SUCCESS"

// update api
export const UPDATE_ITEM_ID = "UPDATE_ITEM_ID"
export const UPDATE_ITEM_ID_SUCCESS = "UPDATE_ITEM_ID_SUCCESS"

// DropDown Api
export const GET_BASEUNIT_FOR_DROPDOWN = "GET_BASEUNIT_FOR_DROPDOWN"
export const GET_BASEUNIT_FOR_DROPDOWN_SUCCESS = "GET_BASEUNIT_FOR_DROPDOWN_SUCCESS"

export const GET_CATEGORYTYPE_FOR_DROPDOWN = "GET_CATEGORYTYPE_FOR_DROPDOWN"
export const GET_CATEGORYTYPE_FOR_DROPDOWN_SUCCESS = "GET_CATEGORYTYPE_FOR_DROPDOWN_SUCCESS"

export const GET_IMAGETYPE_FOR_DROPDOWN = "GET_IMAGETYPE_FOR_DROPDOWN"
export const GET_IMAGETYPE_FOR_DROPDOWN_SUCCESS = "GET_IMAGETYPE_FOR_DROPDOWN_SUCCESS"

export const GET_MRPTYPE_FOR_DROPDOWN = "GET_MRPTYPE_FOR_DROPDOWN"
export const GET_MRPTYPE_FOR_DROPDOWN_SUCCESS = "GET_MRPTYPE_FOR_DROPDOWN_SUCCESS"

export const GET_DIVISION_FOR_DROPDOWN = "GET_DIVISION_FOR_DROPDOWN"
export const GET_DIVISION_FOR_DROPDOWN_SUCCESS = "GET_DIVISION_FOR_DROPDOWN_SUCCESS"

export const GET_PARTY_FOR_DROPDOWN = "GET_PARTY_FOR_DROPDOWN"
export const GET_PARTY_FOR_DROPDOWN_SUCCESS = "GET_PARTY_FOR_DROPDOWN_SUCCESS"

export const GET_GROUP_BY_GROUPTYPE_FOR_DROPDOWN = "GET_GROUP_BY_GROUPTYPE_FOR_DROPDOWN"
export const GET_GROUP_BY_GROUPTYPE_FOR_DROPDOWN_SUCCESS = "GET_GROUP_BY_GROUPTYPE_FOR_DROPDOWN_SUCCESS"

export const GET_SUB_GROUP_BY_GROUP_FOR_DROPDOWN = "GET_SUB_GROUP_BY_GROUP_FOR_DROPDOWN"
export const GET_SUB_GROUP_BY_GROUP_FOR_DROPDOWN_SUCCESS = "GET_SUB_GROUP_BY_GROUP_FOR_DROPDOWN_SUCCESS"

export const GET_CATEGORY_BY_CATEGORYTYPE_FOR_DROPDOWN_API = "GET_CATEGORY_BY_CATEGORYTYPE_FOR_DROPDOWN_API"
export const GET_CATEGORY_BY_CATEGORYTYPE_FOR_DROPDOWN_API_SUCCESS = "GET_CATEGORY_BY_CATEGORYTYPE_FOR_DROPDOWN_API_SUCCESS"



export const ITEM_IMAGE_UPLOAD = "ITEM_IMAGE_UPLOAD"
export const ITEM_IMAGE_UPLOAD_SUCCESS = "ITEM_IMAGE_UPLOAD_SUCCESS"

export const ITEMS_API_ERROR_ACTION = "ITEMS_API_ERROR_ACTION"


