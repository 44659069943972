// Submit Module  All Action type
export const POST_COMPANY_SUBMIT = "POST_COMPANY_SUBMIT"
export const POST_COMPANY_SUBMIT_SUCCESS = 'POST_COMPANY_SUBMIT_SUCCESS'
export const POST_COMPANY_SUBMIT_ERROR = 'POST_COMPANY_SUBMIT_ERROR'

// Fetch Module_List  All Action type 
export const FETCH_COMPANY_LIST = "FETCH_COMPANY_LIST"
export const FETCH_COMPANY_LIST_SUCCESS = 'FETCH_COMPANY_LIST_SUCCESS'
export const FETCH_COMPANY_LIST_ERROR = 'FETCH_COMPANY_LIST_ERROR'

// Fetch Module_List  All Action type 
export const DELETE_COMPANY_ID = "DELETE_COMPANY_ID"
export const DELETE_COMPANY_ID_SUCCESS = 'DELETE_COMPANY_ID_SUCCESS'


export const EDIT_COMPANY_ID = "EDIT_COMPANY_ID"
export const EDIT_COMPANY_ID_SUCCESS = 'EDIT_COMPANY_ID_SUCCESS'

export const UPDATE_COMPANY_ID = "UPDATE_COMPANY_ID"
export const UPDATE_COMPANY_ID_SUCCESS = 'UPDATE_COMPANY_ID_SUCCESS'

export const GET_COMPANYGROUP = "GET_COMPANYGROUP"
export const GET_COMPANYGROUP_SUCCESS = "GET_COMPANYGROUP_SUCCESS"

export const COMPANY_API_ERROR_ACTION = "COMPANY_API_ERROR_ACTION"



