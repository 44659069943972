export const SAVE_VEHICLE_MASTER = "SAVE_VEHICLE_MASTER"
export const SAVE_VEHICLE_MASTER_SUCCESS = "SAVE_VEHICLE_MASTER_SUCCESS"

export const GET_VEHICLE_LIST = "GET_VEHICLE_LIST"
export const GET_VEHICLE_LIST_SUCCESS = "GET_VEHICLE_LIST_SUCCESS"

export const GET_VEHICLE_TYPES_FOR_DROPDOWN = "GET_VEHICLE_TYPES_FOR_DROPDOWN"
export const GET_VEHICLE_TYPES_FOR_DROPDOWN_SUCCESS = "GET_VEHICLE_TYPES_FOR_DROPDOWN_SUCCESS"

export const DELETE_VEHICLE_ID = "DELETE_VEHICLE_ID"
export const DELETE_VEHICLE_ID_SUCCESS = "DELETE_VEHICLE_ID_SUCCESS"

export const EDIT_VEHICLE_ID = "EDIT_VEHICLE_ID"
export const EDIT_VEHICLE_ID_SUCCESS = "EDIT_VEHICLE_ID_SUCCESS"

export const UPDATE_VEHICLE_ID = "UPDATE_VEHICLE_ID"
export const UPDATE_VEHICLE_ID_SUCCESS = "UPDATE_VEHICLE_ID_SUCCESS"

export const VEHICLE_API_ERROR_ACTION = "VEHICLE_API_ERROR_ACTION"


