export const SAVE_CATEGORYTYPE_MASTER = "SAVE_CATEGORYTYPE_MASTER"
export const SAVE_CATEGORYTYPE_MASTER_SUCCESS = "SAVE_CATEGORYTYPE_MASTER_SUCCESS"

export const GET_CATEGORY_TYPE_LIST = "GET_CATEGORY_TYPE_LIST"
export const GET_CATEGORY_TYPE_LIST_SUCCESS = "GET_CATEGORY_TYPE_LIST_SUCCESS"

export const DELETE_CATEGORY_TYPE_ID = "DELETE_CATEGORY_TYPE_ID"
export const DELETE_CATEGORY_TYPE_ID_SUCCESS = "DELETE_CATEGORY_TYPE_ID_SUCCESS"

export const EDIT_CATEGORY_TYPE_ID = "EDIT_CATEGORY_TYPE_ID"
export const EDIT_CATEGORY_TYPE_ID_SUCCESS = "EDIT_CATEGORY_TYPE_ID_SUCCESS"

export const UPDATE_CATEGORY_TYPE_ID = "UPDATE_CATEGORY_TYPE_ID"
export const UPDATE_CATEGORY_TYPE_ID_SUCCESS = "UPDATE_CATEGORY_TYPE_ID_SUCCESS"

export const CATEGORY_TYPE_API_ERROR_ACTION = "CATEGORY_TYPE_API_ERROR_ACTION"
