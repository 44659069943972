export const BREADCRUMB_ITEM_NAME = "BREADCRUMB_ITEM_NAME"

export const BREADCRUMB_DOWN_BTN_DATA = "BREADCRUMB_DOWN_BTN_DATA"
export const BREADCRUMB_SHOW_COUNT_LABLE = "BREADCRUMB_SHOW_COUNT_LABLE"

export const BREADCRUMB_REST = "BREADCRUMB_REST"

export const COMMON_BREADCRUMB_ALL_DETAIL = "COMMON_BREADCRUMB_ALL_DETAIL"

export const BREADCRUMB_NON_DELETE_BUTTON = "BREADCRUMB_NON_DELETE_BUTTON"
export const BREADCRUMB_DELETE_BUTTON = "BREADCRUMB_DELETE_BUTTON"



export const BREADCRUMB_RADIO_BUTTON_VIEW = "BREADCRUMB_RADIO_BUTTON_VIEW"




// export const COMMON_BREADCRUMB_ALL_DETAIL_redux = "COMMON_BREADCRUMB_ALL_DETAIL_redux"
