export const GET_COMPANY_GROUP_LIST = "GET_COMPANY_GROUP_LIST"
export const GET_COMPANY_GROUP_LIST_SUCCESS = "GET_COMPANY_GROUP_LIST_SUCCESS"

export const SAVE_COMPANY_GROUP_MASTER = "SAVE_COMPANY_GROUP_MASTER"
export const SAVE_COMPANY_GROUP_MASTER_SUCCESS = "SAVE_COMPANY_GROUP_MASTER_SUCCESS"

export const EDIT_COMPANY_GROUP_ID = "EDIT_COMPANY_GROUP_ID"
export const EDIT_COMPANY_GROUP_ID_SUCCESS = "EDIT_COMPANY_GROUP_ID_SUCCESS"

export const UPDATE_COMPANY_GROUP_ID = "UPDATE_COMPANY_GROUP_ID"
export const UPDATE_COMPANY_GROUP_ID_SUCCESS = "UPDATE_COMPANY_GROUP_ID_SUCCESS"

export const DELETE_COMPANY_GROUP_ID = "DELETE_COMPANY_GROUP_ID"
export const DELETE_COMPANY_GROUP_ID_SUCCESS = "DELETE_COMPANY_GROUP_ID_SUCCESS"

export const COMPANY_GROUP_API_ERROR_ACTION = "COMPANY_GROUP_API_ERROR_ACTION"


