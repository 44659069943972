export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR_ACTION = "LOGIN_ERROR_ACTION"



export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"

export const API_ERROR = "LOGIN_API_ERROR"
export const API_ERROR_SUCCESS = "API_ERROR_SUCCESS"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN" 

export const GET_USER_DETAILS_AFTER_LOGIN='GET_USER_DETAILS_AFTER_LOGIN'
export const GET_USER_DETAILS_AFTER_LOGIN_SUCCESS='GET_USER_DETAILS_AFTER_LOGIN_SUCCESS'

export const ROLE_ACCESS_API_CALL='ROLE_ACCESS_API_CALL'
export const ROLE_ACCESS_API_CALL_SUCCESS='ROLE_ACCESS_API_CALL_SUCCESS'
export const ROLE_ACCESS_API_CALL_ERROR='ROLE_ACCESS_API_CALL_ERROR'

export const ROLE_ACCESS_API_UPDATE_SUCCESS = "ROLE_ACCESS_API_UPDATE_SUCCESS"

export const DIVISION_DROPDOWN_AFTER_LOGIN_ACTION = "DIVISION_DROPDOWN_AFTER_LOGIN_ACTION"
export const DIVISION_DROPDOWN_AFTER_LOGIN_ACTION_SCUCESS = "DIVISION_DROPDOWN_AFTER_LOGIN_ACTION_SCUCESS"

export const GET_SUPER_ADMIN_API = "GET_SUPER_ADMIN_API"
export const GET_SUPER_ADMIN_API_SUCCESS = "GET_SUPER_ADMIN_API_SUCCESS"

export const LOGOUT_REST = "LOGOUT_REST"

export const RESET_ROLE_ACCESS_ACTION = "RESET_ROLE_ACCESS_ACTION"

