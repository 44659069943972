export const GO_BUTTON_FOR_RATE_MASTER = "GO_BUTTON_FOR_RATE_MASTER"
export const GO_BUTTON_FOR_RATE_MASTER_SUCCESS = "GO_BUTTON_FOR_RATE_MASTER_SUCCESS"

export const SAVE_RATE_MASTER = "SAVE_RATE_MASTER"
export const SAVE_RATE_MASTER_SUCCESS = "SAVE_RATE_MASTER_SUCCESS"

export const DELETE_RATE_ID_FOR_MASTER = "DELETE_RATE_ID_FOR_MASTER"
export const DELETE_RATE_ID_FOR_MASTER_SUCCESS = "DELETE_RATE_ID_FOR_MASTER_SUCCESS"

export const GET_RATE_LIST = "GET_RATE_LIST"
export const GET_RATE_LIST_SUCCESS = "GET_RATE_LIST_SUCCESS"

export const DELETE_RATE_LIST_ID = "DELETE_RATE_LIST_ID"
export const DELETE_RATE_LIST_ID_SUCCESS = "DELETE_RATE_LIST_ID_SUCCESS"

export const RATE_API_ERROR_ACTION = "RATE_API_ERROR_ACTION"