export const GET_SUPPLIER = 'GET_SUPPLIER'
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS'

export const GET_SUPPLIER_ADDRESS = 'GET_SUPPLIER_ADDRESS'
export const GET_SUPPLIER_ADDRESS_SUCCESS = 'GET_SUPPLIER_ADDRESS_SUCCESS'

export const GET_ORDER_TYPE = 'GET_ORDER_TYPE'
export const GET_ORDER_TYPE_SUCCESS = 'GET_ORDER_TYPE_SUCCESS'

export const GET_VENDER = 'GET_VENDER'
export const GET_VENDER_SUCCESS = 'GET_VENDER_SUCCESS'

export const GET_CUSTOMER = 'GET_CUSTOMER'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'

export const GET_VENDER_SUPPLIER_CUSTOMER = 'GET_VENDER_SUPPLIER_CUSTOMER'
export const GET_VENDER_SUPPLIER_CUSTOMER_SUCCESS = 'GET_VENDER_SUPPLIER_CUSTOMER_SUCCESS'

export const SSDD_LIST_UNDER_COMPANY = 'SSDD_LIST_UNDER_COMPANY'
export const SSDD_LIST_UNDER_COMPANY_SUCCESS = 'SSDD_LIST_UNDER_COMPANY_SUCCESS'

export const RETAILER_LIST = 'RETAILER_LIST'
export const RETAILER_LIST_SUCCESS = 'RETAILER_LIST_SUCCESS'

export const PARTY_DROPDOWN_LIST = 'PARTY_DROPDOWN_LIST'
export const PARTY_DROPDOWN_LIST_SUCCESS = 'PARTY_DROPDOWN_LIST_SUCCESS'


export const SUB_EMPLOYEE_LIST = 'SUB_EMPLOYEE_LIST'
export const SUB_EMPLOYEE_LIST_SUCCESS = 'SUB_EMPLOYEE_LIST_SUCCESS'

export const PARTY_ON_CLUSTER_SUBCLUSTER_LIST = 'PARTY_ON_CLUSTER_SUBCLUSTER_LIST'
export const PARTY_ON_CLUSTER_SUBCLUSTER_LIST_SUCCESS = 'PARTY_ON_CLUSTER_SUBCLUSTER_LIST_SUCCESS'







export const COMMON_API_REDUCER_ERROR_ACTION = 'COMMON_API_REDUCER_ERROR_ACTION'

