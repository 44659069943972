
export const GET_TARGET_UPLOAD_LIST = "GET_TARGET_UPLOAD_LIST"
export const GET_TARGET_UPLOAD_LIST_SUCCESS = "GET_TARGET_UPLOAD_LIST_SUCCESS"


export const DELETE_TARGET_UPLOAD_LIST_ID = "DELETE_TARGET_UPLOAD_LIST_ID"
export const DELETE_TARGET_UPLOAD_LIST_ID_SUCCESS = "DELETE_TARGET_UPLOAD_LIST_ID_SUCCESS"

export const SAVE_TARGET_UPLOAD_MASTER = "SAVE_TARGET_UPLOAD_MASTER"
export const SAVE_TARGET_UPLOAD_MASTER_SUCCESS = "SAVE_TARGET_UPLOAD_MASTER_SUCCESS"

export const TARGET_UPLOAD_API_ERROR_ACTION = "TARGET_UPLOAD_API_ERROR_ACTION"


export const EDIT_TARGET_UPLOAD_ID = "EDIT_TARGET_UPLOAD_ID"
export const EDIT_TARGET_UPLOAD_ID_SUCCESS = "EDIT_TARGET_UPLOAD_ID_SUCCESS"

