export const GET_PARTY_EMPLOYEE_DETAILS = 'GET_PARTY_EMPLOYEE_DETAILS'
export const GET_PARTY_EMPLOYEE_DETAILS_API_SUCCESS = 'GET_PARTY_EMPLOYEE_DETAILS_API_SUCCESS'



export const GET_PARTY_EMPLOYEE_DETAILS_API_ERROR_ACTION = 'GET_PARTY_EMPLOYEE_DETAILS_API_ERROR_ACTION'






