// post api
export const SAVE_MARGIN_MASTER = "SAVE_MARGIN_MASTER"
export const SAVE_MARGIN_MASTER_SUCCESS = "SAVE_MARGIN_MASTER_SUCCESS"

//listpage
export const GET_MARGIN_LIST = "GET_MARGIN_LIST"
export const GET_MARGIN_LIST_SUCCESS = "GET_MARGIN_LIST_SUCCESS"

// delete api for List Page
export const DELETE_MARGIN_LIST_ID = "DELETE_MARGIN_LIST_ID"
export const DELETE_MARGIN_LIST_ID_SUCCESS = "DELETE_MARGIN_LIST_ID_SUCCESS"

// Go Button post api
export const GO_BUTTON_FOR_MARGIN_MASTER = "GO_BUTTON_FOR_MARGIN_MASTER"
export const GO_BUTTON_FOR_MARGIN_MASTER_SUCCESS = "GO_BUTTON_FOR_MARGIN_MASTER_SUCCESS"

// delete api Margin Master Page
export const DELETE_ID_FOR_MARGIN_MASTER = "DELETE_ID_FOR_MARGIN_MASTER"
export const DELETE_ID_FOR_MARGIN_MASTER_SUCCESS = "DELETE_ID_FOR_MARGIN_MASTER_SUCCESS"

export const MARGIN_API_ERROR_ACTION = "MARGIN_API_ERROR_ACTION"


