export const POST_METHOD_HANDLER_FOR_TERMSANDCONDITIONS_MASTER_API="POST_METHOD_HANDLER_FOR_TERMSANDCONDITIONS_MASTER_API"
export const POST_METHOD_HANDLER_FOR_TERMSANDCONDITIONS_MASTER_API_SUCCESS="POST_METHOD_HANDLER_FOR_TERMSANDCONDITIONS_MASTER_API_SUCCESS"

export const GET_METHOD_FOR_TERMSANDCONDITIONSLIST_API="GET_METHOD_FOR_TERMSANDCONDITIONSLIST_API"
export const GET_METHOD_FOR_TERMSANDCONDITIONSLIST_API_SUCCESS="GET_METHOD_FOR_TERMSANDCONDITIONSLIST_API_SUCCESS"

export const EDIT_METHOD_FOR_TERMSANDCONDITIONSLIST_API="EDIT_METHOD_FOR_TERMSANDCONDITIONSLIST_API"
export const EDIT_METHOD_FOR_TERMSANDCONDITIONSLIST_API_SUCCESS="EDIT_METHOD_FOR_TERMSANDCONDITIONSLIST_API_SUCCESS"

export const UPDATE_METHOD_FOR_TERMSANDCONDITIONSLIST_API="UPDATE_METHOD_FOR_TERMSANDCONDITIONSLIST_API"
export const UPDATE_METHOD_FOR_TERMSANDCONDITIONSLIST_API_SUCCESS="UPDATE_METHOD_FOR_TERMSANDCONDITIONSLIST_API_SUCCESS"

export const DELETE_METHOD_FOR_TERMSANDCONDITIONSLIST_API="DELETE_METHOD_FOR_TERMSANDCONDITIONSLIST_API"
export const DELETE_METHOD_FOR_TERMSANDCONDITIONSLIST_API_SUCCESS="DELETE_METHOD_FOR_TERMSANDCONDITIONSLIST_API_SUCCESS"

export const TERMS_AND_CONDITIONS_API_ERROR_ACTION="TERMS_AND_CONDITIONS_API_ERROR_ACTION"