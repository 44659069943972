
export const RECEIPT_GO_BUTTON_MASTER = "RECEIPT_GO_BUTTON_MASTER"
export const RECEIPT_GO_BUTTON_MASTER_SUCCESS = "RECEIPT_GO_BUTTON_MASTER_SUCCESS"

export const GET_OPENING_BALANCE = "GET_OPENING_BALANCE"
export const GET_OPENING_BALANCE_SUCCESS = "GET_OPENING_BALANCE_SUCCESS"

export const SAVE_RECEIPT_MASTER = "SAVE_RECEIPT_MASTER"
export const SAVE_RECEIPT_MASTER_SUCCESS = "SAVE_RECEIPT_MASTER_SUCCESS"

export const RECEIPT_LIST_API = 'RECEIPT_LIST_API'
export const RECEIPT_LIST_API_SUCCESS = 'RECEIPT_LIST_API_SUCCESS'

export const RECEIPT_TYPE_API = 'RECEIPT_TYPE_API'
export const RECEIPT_TYPE_API_SUCCESS = 'RECEIPT_TYPE_API_SUCCESS'

export const DELETE_RECEIPT_LIST = 'DELETE_RECEIPT_LIST'
export const DELETE_RECEIPT_LIST_SUCCESS = 'DELETE_RECEIPT_LIST_SUCCESS'

export const BANK_LIST_API = 'BANK_LIST_API'
export const BANK_LIST_API_SUCCESS = 'BANK_LIST_API_SUCCESS'

export const RECEIPT_LIST_FILTERS = 'RECEIPT_LIST_FILTERS'

export const PAYMENT_ENTRY_LIST_FILTERS = 'PAYMENT_ENTRY_LIST_FILTERS'

export const RECEIPT_AND_PAYMENT_API_ERROR_ACTION="RECEIPT_AND_PAYMENT_API_ERROR_ACTION"