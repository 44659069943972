
export const GO_BUTTON_IMPORT_FIELD_MAP_ADD = "GO_BUTTON_IMPORT_FIELD_MAP_ADD"
export const GO_BUTTON_IMPORT_FIELD_MAP_ADD_SUCCESS = "GO_BUTTON_IMPORT_FIELD_MAP_ADD_SUCCESS"

export const SAVE_IMPORT_FIELD_MAP = "SAVE_IMPORT_FIELD_MAP"
export const SAVE_IMPORT_FIELD_MAP_SUCCESS = "SAVE_IMPORT_FIELD_MAP_SUCCESS"

export const GET_IMPORT_FIELD_MAP_LIST = "GET_IMPORT_FIELD_MAP_LIST"
export const GET_IMPORT_FIELD_MAP_LIST_SUCCESS = "GET_IMPORT_FIELD_MAP_LIST_SUCCESS"

export const IMPORT_FIELD_MAP_API_ERROR_ACTION = "IMPORT_FIELD_MAP_API_ERROR_ACTION"