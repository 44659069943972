export const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS'
export const GET_DASHBOARD_DETAILS_SUCCESS = 'GET_DASHBOARD_DETAILS_SUCCESS'


export const GET_DASHBOARD_ORDER_DATA_DETAILS = 'GET_DASHBOARD_ORDER_DATA_DETAILS'
export const GET_DASHBOARD_ORDER_DATA_DETAILS_SUCCESS = 'GET_DASHBOARD_ORDER_DATA_DETAILS_SUCCESS'



export const GET_DASHBOARD_INVOICE_DATA_DETAILS = 'GET_DASHBOARD_INVOICE_DATA_DETAILS'
export const GET_DASHBOARD_INVOICE_DATA_DETAILS_SUCCESS = 'GET_DASHBOARD_INVOICE_DATA_DETAILS_SUCCESS'


export const GET_DASHBOARD_GRN_DATA_DETAILS = 'GET_DASHBOARD_GRN_DATA_DETAILS'
export const GET_DASHBOARD_GRN_DATA_DETAILS_SUCCESS = 'GET_DASHBOARD_GRN_DATA_DETAILS_SUCCESS'



