export const MAKE_GRN_MODE_1_ACTION = 'MAKE_GRN_MODE_1_ACTION'
export const MAKE_GRN_MODE_1_ACTION_SUCCESS = 'MAKE_GRN_MODE_1_ACTION_SUCCESS'

export const GET_GRN_LIST_PAGE = 'GET_GRN_LIST_PAGE'
export const GET_GRN_LIST_PAGE_SUCCESS = 'GET_GRN_LIST_PAGE_SUCCESS'

export const SAVE_GRN_FROM_GRN_PAGE_ACTION = 'SAVE_GRN_FROM_GRN_PAGE_ACTION'
export const SAVE_GRN_FROM_GRN_PAGE_SUCCESS = 'SAVE_GRN_FROM_GRN_PAGE_SUCCESS'

export const EDIT_GRN_FOR_GRN_PAGE = 'EDIT_GRN_FOR_GRN_PAGE'
export const EDIT_GRN_FOR_GRN_PAGE_SUCCESS = 'EDIT_GRN_FOR_GRN_PAGE_SUCCESS'

export const UPDATE_GRN_ID_FROM_GRN_PAGE = 'UPDATE_GRN_ID_FROM_GRN_PAGE'
export const UPDATE_GRN_ID_FROM_GRN_PAGE_SUCCESS = 'UPDATE_GRN_ID_FROM_GRN_PAGE_SUCCESS'

export const DELETE_GRN_FOR_GRN_PAGE = 'DELETE_GRN_FOR_GRN_PAGE'
export const DELETE_GRN_FOR_GRN_PAGE_SUCCESS = 'DELETE_GRN_FOR_GRN_PAGE_SUCCESS'

export const GO_BUTTON_FOR_GRN_PAGE = 'GO_BUTTON_FOR_GRN_PAGE'
export const GO_BUTTON_FOR_GRN_PAGE_SUCCESS = 'GO_BUTTON_FOR_GRN_PAGE_SUCCESS'

export const HIDE_INVOICE_FOR_GRN_ACTION = 'HIDE_INVOICE_FOR_GRN_ACTION'
export const HIDE_INVOICE_FOR_GRN_ACTION_SUCCESS = 'HIDE_INVOICE_FOR_GRN_ACTION_SUCCESS'




export const GRN_API_ERROR_ACTION = 'GRN_API_ERROR_ACTION'














