// get api
export const GET_PARTY_LIST_API = "GET_PARTY_LIST_API"
export const GET_PARTY_LIST_API_SUCCESS = "GET_PARTY_LIST_API_SUCCESS"

// post api
export const POST_PARTY_DATA = "POST_PARTY_DATA"
export const POST_PARTY_DATA_SUCCESS = "POST_PARTY_DATA_SUCCESS"

// delete api
export const DELETE_PARTY_ID = "DELETE_PARTY_ID"
export const DELETE_PARTY_ID_SUCCESS = "DELETE_PARTY_ID_SUCCESS"

// edit api
export const EDIT_PARTY_ID = "EDIT_PARTY_ID"
export const EDIT_PARTY_ID_SUCCESS = "EDIT_PARTY_ID_SUCCESS"

// update api
export const UPDATE_PARTY_ID = "UPDATE_PARTY_ID"
export const UPDATE_PARTY_ID_SUCCESS = "UPDATE_PARTY_ID_SUCCESS"

//AddressTypes
export const GET_ADDRESSTYPES = "GET_ADDRESSTYPES"
export const GET_ADDRESSTYPES_SUCCESS = "GET_ADDRESSTYPES_SUCCESS"

// GetDistrictOnState API
export const GET_DISTRICT_ON_STATE = "GET_DISTRICT_ON_STATE"
export const GET_DISTRICT_ON_STATE_SUCCESS = "GET_DISTRICT_ON_STATE_SUCCESS"

// GetPartyTypeByDivisionTypeID API dependent on DivisionTypes api
export const GET_PARTTYPE_BY_DIVISIONTYPES_ID = "GET_PARTTYPE_BY_DIVISIONTYPES_ID"
export const GET_PARTTYPE_BY_DIVISIONTYPES_ID_SUCCESS = "GET_PARTTYPE_BY_DIVISIONTYPES_ID_SUCCESS"

export const GET_COMPANY_BY_DIVISIONTYPES_ID = "GET_COMPANY_BY_DIVISIONTYPES_ID"
export const GET_COMPANY_BY_DIVISIONTYPES_ID_SUCCESS = "GET_COMPANY_BY_DIVISIONTYPES_ID_SUCCESS"

export const PARTY_ADDRESS_DELETE_ID = "PARTY_ADDRESS_DELETE_ID"
export const PARTY_ADDRESS_DELETE_ID_SUCCESS = "PARTY_ADDRESS_DELETE_ID_SUCCESS"

export const PARTY_LIST_FOR_APPROVAL_ACTION = "PARTY_LIST_FOR_APPROVAL_ACTION"
export const PARTY_LIST_FOR_APPROVAL_SUCCESS = "PARTY_LIST_FOR_APPROVAL_SUCCESS"

export const GET_PARTY_LIST_FOR_APPROVAL_ACTION = "GET_PARTY_LIST_FOR_APPROVAL_ACTION"
export const GET_PARTY_LIST_FOR_APPROVAL_SUCCESS = "GET_PARTY_LIST_FOR_APPROVAL_SUCCESS"

export const PARTY_API_ERROR_ACTION = "PARTY_API_ERROR_ACTION"



