export const POST_METHOD_HANDLER_FOR_GROUPMASTER_API = "POST_METHOD_HANDLER_FOR_GROUPMASTER_API"
export const POST_METHOD_HANDLER_FOR_GROUPMASTER_API_SUCCESS = "POST_METHOD_HANDLER_FOR_GROUPMASTER_API_SUCCESS"

export const GET_GROUP_LIST = "GET_GROUP_LIST"
export const GET_GROUP_LIST_SUCCESS = "GET_GROUP_LIST_SUCCESS"


export const DELETE_GROUP_LIST_ID = "DELETE_GROUPMASTER_ID"
export const DELETE_GROUP_LIST_ID_SUCCESS = "DELETE_GROUPMASTER_ID_SUCCESS"

export const EDIT_GROUPMASTER_ID = "EDIT_GROUPMASTER_ID"
export const EDIT_GROUPMASTER_ID_SUCCESS = "EDIT_GROUPMASTER_ID_SUCCESS"

export const UPDATE_GROUPMASTER_ID = "UPDATE_GROUPMASTER_ID"
export const UPDATE_GROUPMASTER_ID_SUCCESS = "UPDATE_GROUPMASTER_ID_SUCCESS"

export const SAVE_GROUP_MASTER = "SAVE_GROUP_MASTER"
export const SAVE_GROUP_MASTER_SUCCESS = "SAVE_GROUP_MASTER_SUCCESS"

export const GROUP_API_ERROR_ACTION = "GROUP_API_ERROR_ACTION"


