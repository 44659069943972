export const GET_ORDER_ITEMS_FOR_ORDER_PAGE = "GET_ORDER_ITEMS_FOR_ORDER_PAGE"
export const GET_ORDER_ITEMS_FOR_ORDER_PAGE_SUCCESS = 'GET_ORDER_ITEMS_FOR_ORDER_PAGE_SUCCESS'

export const SAVE_ORDER_FROM_ORDER_PAGE = 'SAVE_ORDER_FROM_ORDER_PAGE'
export const SAVE_ORDER_FROM_ORDER_PAGE_SUCCESS = 'SAVE_ORDER_FROM_ORDER_PAGE_SUCCESS'

export const GET_ORDER_LIST = 'GET_ORDER_LIST'
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS'

export const GET_ORDER_LIST_MESSAGE = 'GET_ORDER_LIST_MESSAGE'

export const EDIT_ORDER_FOR_ORDER_PAGE = 'EDIT_ORDER_FOR_ORDER_PAGE'
export const EDIT_ORDER_FOR_ORDER_PAGE_SUCCESS = 'EDIT_ORDER_FOR_ORDER_PAGE_SUCCESS'

export const GET_DIVISIONORDER_LIST = 'GET_DIVISIONORDER_LIST'
export const GET_DIVISIONORDER_LIST_SUCCESS = 'GET_DIVISIONORDER_LIST_SUCCESS'

export const UPDATE_ORDER_ID_FROM_ORDER_PAGE = 'UPDATE_ORDER_ID_FROM_ORDER_PAGE'
export const UPDATE_ORDER_ID_FROM_ORDER_PAGE_SUCCESS = 'UPDATE_ORDER_ID_FROM_ORDER_PAGE_SUCCESS'

export const DELETE_ORDER_FOR_ORDER_PAGE = 'DELETE_ORDER_FOR_ORDER_PAGE'
export const DELETE_ORDER_FOR_ORDER_PAGE_SUCCESS = 'DELETE_ORDER_FOR_ORDER_PAGE_SUCCESS'

export const GO_BUTTON_FOR_ORDER_PAGE = 'GO_BUTTON_FOR_ORDER_PAGE'
export const GO_BUTTON_FOR_ORDER_PAGE_SUCCESS = 'GO_BUTTON_FOR_ORDER_PAGE_SUCCESS'

export const GET_ORDER_LIST_PAGE = 'GET_ORDER_LIST_PAGE'
export const GET_ORDER_LIST_PAGE_SUCCESS = 'GET_ORDER_LIST_PAGE_SUCCESS'


export const ORDER_APPROVAL_ACTION = 'ORDER_APPROVAL_ACTION'
export const ORDER_APPROVAL_ACTION_SUCCESS = 'ORDER_APPROVAL_ACTION_SUCCESS'

export const GET_ORDER_APPROVAL_DETAIL = 'GET_ORDER_APPROVAL_DETAIL'
export const GET_ORDER_APPROVAL_DETAIL_SUCCESS = 'GET_ORDER_APPROVAL_DETAIL_SUCCESS'

export const ORDER_API_ERROR_ACTION = 'ORDER_API_ERROR_ACTION'

export const POST_ORDER_CONFIRM_API = 'POST_ORDER_CONFIRM_API'
export const POST_ORDER_CONFIRM_API_SUCCESS = 'POST_ORDER_CONFIRM_API_SUCCESS'


export const ORDER_SINGLE_GET_API = 'ORDER_SINGLE_GET_API'
export const ORDER_SINGLE_GET_API_SUCCESS = 'ORDER_SINGLE_GET_API_SUCCESS'

