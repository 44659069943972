
export const GET_COUNTRY_LIST_ACTION = "GET_COUNTRY_LIST_ACTION"
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS"

export const SAVE_COUNTRY_MASTER_ACTION = "SAVE_COUNTRY_MASTER_ACTION"
export const SAVE_COUNTRY_MASTER_SUCCESS = "SAVE_COUNTRY_MASTER_SUCCESS"

export const EDIT_COUNTRY_ID_ACTION = "EDIT_COUNTRY_ID_ACTION"
export const EDIT_COUNTRY_ID_SUCCESS = "EDIT_COUNTRY_ID_SUCCESS"

export const UPDATE_COUNTRY_ID_ACTION = "UPDATE_COUNTRY_ID_ACTION"
export const UPDATE_COUNTRY_ID_SUCCESS = "UPDATE_COUNTRY_ID_SUCCESS"

export const DELETE_COUNTRY_ID_ACTION = "DELETE_COUNTRY_ID_ACTION"
export const DELETE_COUNTRY_ID_SUCCESS = "DELETE_COUNTRY_ID_SUCCESS"

export const COUNTRY_API_ERROR_ACTION = "COUNTRY_API_ERROR_ACTION"


