export const SAVE_CLAIM_TRACKING_ENTRY = "SAVE_CLAIM_TRACKING_ENTRY"
export const SAVE_CLAIM_TRACKING_ENTRY_SUCCESS = "SAVE_CLAIM_TRACKING_ENTRY_SUCCESS"

export const GET_CLAIM_TRACKING_ENTRY_LIST = "GET_CLAIM_TRACKING_ENTRY_LIST"
export const GET_CLAIM_TRACKING_ENTRY_LIST_SUCCESS = "GET_CLAIM_TRACKING_ENTRY_LIST_SUCCESS"

export const EDIT_CLAIM_TRACKING_ENTRY_ID = "EDIT_CLAIM_TRACKING_ENTRY_ID"
export const EDIT_CLAIM_TRACKING_ENTRY_ID_SUCCESS = "EDIT_CLAIM_TRACKING_ENTRY_ID_SUCCESS"

export const UPDATE_CLAIM_TRACKING_ENTRY_ID = "UPDATE_CLAIM_TRACKING_ENTRY_ID"
export const UPDATE_CLAIM_TRACKING_ENTRY_ID_SUCCESS = "UPDATE_CLAIM_TRACKING_ENTRY_ID_SUCCESS"

export const DELETE_CLAIM_TRACKING_ENTRY_ID = "DELETE_CLAIM_TRACKING_ENTRY_ID"
export const DELETE_CLAIM_TRACKING_ENTRY_ID_SUCCESS = "DELETE_CLAIM_TRACKING_ENTRY_ID_SUCCESS"

export const CLAIM_TRACKING_ENTRY_API_ERROR_ACTION = "CLAIM_TRACKING_ENTRY_API_ERROR_ACTION"

