export const SAVE_BOM_MASTER = 'SAVE_BOM_MASTER'
export const SAVE_BOM_MASTER_SUCCESS = 'SAVE_BOM_MASTER_SUCCESS'

export const GET_ITEM_UNITS_DROPDOWN_API = 'GET_ITEM_UNITS_DROPDOWN_API'
export const GET_ITEM_UNITS_DROPDOWN_API_SUCCESS = 'GET_ITEM_UNITS_DROPDOWN_API_SUCCESS'

export const GET_BOM_LIST_PAGE = 'GET_BOM_LIST_PAGE'
export const GET_BOM_LIST_PAGE_SUCCESS = 'GET_BOM_LIST_PAGE_SUCCESS'

export const EDIT_BOM_LIST_ID = 'EDIT_BOM_LIST_ID'
export const EDIT_BOM_LIST_ID_SUCCESS = 'EDIT_BOM_LIST_ID_SUCCESS'

export const UPDATE_BOM_LIST = 'UPDATE_BOM_LIST'
export const UPDATE_BOM_LIST_SUCCESS = 'UPDATE_BOM_LIST_SUCCESS'

export const DELETE_BOM_LIST_PAGE = 'DELETE_BOM_LIST_PAGE'
export const DELETE_BOM_LIST_PAGE_SUCCESS = 'DELETE_BOM_LIST_PAGE_SUCCESS'

export const BOM_API_ERROR_ACTION = 'BOM_API_ERROR_ACTION'
